import { createApp } from 'vue'
import loading from '@/components/mode/loading.vue'

const createDialog = (status, option = {}) => {
    if(status=='close'){
        let s  = document.getElementById('newDivId')
        document.body.removeChild(s);
    }else{
        const mountNode = document.createElement('div')
        mountNode.id="newDivId";
        const Instance = createApp(loading, {
            show: true,
            status,
            ...option,
            close: () => { 
                Instance.unmount(mountNode); 
                document.body.removeChild(mountNode);
            }
        })
     
        document.body.appendChild(mountNode)
        Instance.mount(mountNode)
    }

}

export default createDialog
