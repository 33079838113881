
import login from '@/views/registration/login.vue';
import sin from '@/views/registration/index.vue';
import register from '@/views/registration/register.vue';
const regRoute = [
    {
        path: '/sin',
        name: 'sin',
        component: sin,
        children:[
            {
                path: '/login',
                name: 'login',
                component: login
            },
            {
                path: '/register',
                name: 'register',
                component: register
            },
        ]
    },

]

export default regRoute