import serve from './serve';

export function statistics(params){//获取用户信息
    return serve({
        url:'v1/liuhe/statistics',
        method :'get',
        params:params,
    })
}

export function number_attr(params){//获取生肖信息
    return serve({
        url:'v1/liuhe/number_attr',
        method :'get',
        params:params,
    })
}
export function number_attr2(params){//获取生肖信息
    return serve({
        url:'v1/liuhe/number_attr2',
        method :'get',
        params:params,
    })
}
