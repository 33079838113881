import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3f4fc9f3"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-master-search"
};
import search from '@/components/page/search.vue';
import { discussList } from '@/utils/https/master';
import { onBeforeMount } from 'vue';

import { useRouter, useRoute } from 'vue-router';
import { reactive, ref, toRefs } from 'vue';
export default {
  __name: 'search',
  setup(__props) {
    let router = useRouter();
    let route = useRoute();
    let data = reactive({
      lottery: 0,
      type: 1,
      sort: 1,
      page: 1,
      keyword: '',
      discussInfo: [],
      activeIndex: 0
    });
    onBeforeMount(() => {
      data.lottery = route.query.lottery;
      data.type = route.query.type;
      data.sort = route.query.sort;
      data.activeIndex = route.query.activeIndex;
    });
    let discuss = (lottery, type, sort = '1', page, keyword) => {
      discussList({
        lotteryType: lottery,
        type: type,
        sort: sort,
        page: page,
        keyword: keyword
      }).then(res => {
        if (res == undefined) return false;
        if (res.data.status == 40000) {
          finished.value = true;
        } else {
          let datas = res.data.data.data;
          loading.value = false;
          data.discussInfo.push(...datas);
        }
      });
    };
    const loading = ref(false);
    const finished = ref(true);
    const onLoad = () => {
      if (data.keyword != '') {
        discuss(data.lottery, data.type, data.sort, data.page, data.keyword);
        data.page++;
      } else {
        finished.value = true;
      }
    };
    let {
      title
    } = toRefs(data);
    let onSubmit = e => {
      finished.value = false;
      data.discussInfo.length = 0;
      data.page = 1;
      data.keyword = e;
      loading.value = true;
      finished.value = false;
      onLoad();
    };
    let articleDes = item => {
      //进入详情
      if (data.activeIndex == 0) {
        router.push({
          path: '/master/details',
          query: {
            id: item.id
          }
        });
      } else {
        router.push({
          path: '/gallery/analysis/details',
          query: {
            id: item.id
          }
        });
      }
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_articles = _resolveComponent("articles");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "论坛搜索"
      }, {
        tkCont: _withCtx(() => [_createVNode(search, {
          returns: false,
          onOnSubmit: _unref(onSubmit)
        }, null, 8, ["onOnSubmit"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_unref(data).discussInfo.length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createBlock(_component_articles, {
          key: 1,
          articlesArray: _unref(data).discussInfo,
          onArticleDes: _unref(articleDes),
          guess: "高手"
        }, null, 8, ["articlesArray", "onArticleDes"]))]),
        _: 1
      }, 8, ["loading", "finished"])])], 64);
    };
  }
};