
import setup from '@/views/me/setup/setup.vue'
import password from '@/views/me/setup/password.vue'
import BindingAccount from '@/views/me/setup/BindingAccount.vue'
import feedback from '@/views/me/setup/feedback.vue'
import idea from '@/views/me/setup/idea.vue'
import ideaDesc from '@/views/me/setup/ideaDesc.vue'
import account from '@/views/me/setup/account.vue'
import about from '@/views/me/setup/about.vue'
import money_password from '@/views/me/setup/money_password.vue'
import money_password_exid from '@/views/me/setup/money_password_exid.vue'


import topup from '@/views/me/purse/topup.vue'
import exchange from '@/views/me/purse/exchange.vue'
import withdraw from '@/views/me/purse/withdraw.vue'


import collect from '@/views/me/collect/collect.vue'
import collectSearch from '@/views/me/collect/search.vue'
import comment from '@/views/me/comment.vue'
import follow from '@/views/me/follow.vue'
import fans from '@/views/me/fans.vue'
import Blacklist from '@/views/me/Blacklist.vue'
import upvote from '@/views/me/upvote.vue'

import room from '@/views/me/room/room.vue'
import roomSearch from '@/views/me/room/search.vue'

import report from '@/views/me/report/report.vue'
import reportdesc from '@/views/me/report/reportDesc.vue'
import rankingFans from '@/views/me/ranking/fans.vue'
import rankingShare from '@/views/me/ranking/share.vue'
import rankingGrade from '@/views/me/ranking/grade.vue'
import rankingGift from '@/views/me/ranking/gift.vue'
import activity from '@/views/me/activity.vue'
import Invite from '@/views/me/Invite.vue'
import grade from '@/views/me/grade/grade.vue'
import integral from '@/views/me/grade/integral.vue'
import kf from '@/views/me/kf.vue'
import addinvite from '@/views/me/addinvite.vue'
import bonus from '@/views/me/bonus.vue'
import record from '@/views/me/record.vue'
import recordDesc from '@/views/me/recordDesc.vue'
import mobile from '@/views/me/mobile.vue'

const meRoute = [
 
    {
        path: '/me/setup',
        name: 'setup',
        component: setup,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/me/setup/password',
        name: 'setupPassword',
        component: password,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/me/setup/BindingAccount',
        name: 'BindingAccount',
        component: BindingAccount,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/me/setup/feedback',
        name: 'feedback',
        component: feedback,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/me/setup/feedback/idea',
        name: 'idea',
        component: idea
    },
    {
        path: '/me/setup/feedback/details',
        name: 'ideaDesc',
        component: ideaDesc,
        meta:{
            needLogin: true
        }
    },

    {
        path: '/me/setup/account',
        name: 'account',
        component: account,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/me/setup/about',
        name: 'about',
        component: about,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/me/purse/topup',
        name: 'topup',
        component: topup,
        meta:{
            needLogin: true
        }
    },  
     {
        path: '/me/purse/exchange',
        name: 'exchange',
        component: exchange,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/me/purse/withdraw',
        name: 'withdraw',
        component: withdraw,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/me/collect',
        name: 'collect',
        component: collect,
        meta:{
            needLogin: true,
            keepAlive: true,
        }
    },
    {
        path: '/me/collect/search',
        name: 'collectSearch',
        component: collectSearch,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/me/comment',
        name: 'mEcomment',
        component: comment,
        meta:{
            needLogin: true,
            keepAlive: true,
        }
    },
    {
        path: '/me/follow',
        name: 'follow',
        component: follow,
        meta:{
            needLogin: true,
        }
    },
    {
        path: '/me/fans',
        name: 'meFans',
        component: fans,
        meta:{
            needLogin: true,
        }
    },
    {
        path: '/me/blacklist',
        name: 'Blacklist',
        component: Blacklist,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/me/upvote',
        name: 'upvote',
        component: upvote,
        meta:{
            needLogin: true,
            keepAlive: true,
        }
    },
    {
        path: '/me/room',
        name: 'room',
        component: room,
        meta:{
            needLogin: true
        }
    }, 
    {
        path: '/me/room/search',
        name: 'roomSearch',
        component: roomSearch,
        meta:{
            needLogin: true
        }
    }, 
      
     {
        path: '/me/report',
        name: 'report',
        component: report,
        meta: {
            keepAlive: true,
            needLogin: true
        },
    },   
    {
       path: '/me/report/details',
       name: 'reportdesc',
       component: reportdesc,
       meta:{
        needLogin: true
    }
   },


   {
    path: '/me/ranking/fans',
    name: 'rankingFans',
    component: rankingFans,

    }
    ,
   {
    path: '/me/ranking/share',
    name: 'rankingShare',
    component: rankingShare,
  
    } ,
    {
    path: '/me/ranking/grade',
    name: 'rankingGrade',
    component: rankingGrade,
  
    }
    ,
    {
    path: '/me/ranking/gift',
    name: 'rankingGift',
    component: rankingGift,

    }
    ,
    {
    path: '/me/ranking/gift',
    name: 'rankingGift',
    component: rankingGift,

    }
    ,
    {
        path: '/me/activity',
        name: 'activity',
        component: activity,
        // meta:{
        //     needLogin: true
        // }
    }
    ,
    {   
        path: '/me/invite',
        name: 'Invite',
        component: Invite,
        meta:{
            needLogin: true
        }
    }
    ,
    {   
        path: '/me/grade',
        name: 'grade',
        component: grade,
        // meta:{
        //     needLogin: true
        // }
    }
    ,
    {   
        path: '/me/grade/integral',
        name: 'integral',
        component: integral,
        meta:{
            needLogin: true
        }
    }
    ,
    {   
        path: '/me/kf',
        name: 'kf',
        component: kf
    }
    ,
    {   
        path: '/me/addinvite',
        name: 'addinvite',
        component: addinvite
    }
    ,
    {   
        path: '/me/setup/money/password',
        name: 'money_password',
        component: money_password
    }
    ,
    {   
        path: '/me/setup/money/password/exit',
        name: 'money_password_exid',
        component: money_password_exid
    }
    ,
    {   
        path: '/me/bonus',
        name: 'bonus',
        component: bonus,
        meta:{
            needLogin: true
        }
    } ,
    {   
        path: '/me/bonus/record',
        name: 'record',
        component: record,
        meta:{
            needLogin: true,
            keepAlive: true,
        }
    }  ,
    {   
        path: '/me/bonus/record/details',
        name: 'recordDesc',
        component: recordDesc,
        meta:{
            needLogin: true
        }
    }  ,
    {   
        path: '/me/mobile',
        name: 'mobile',
        component: mobile,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/me/earnings',
        name: 'earnings',
        component: () => import('@/views/me/earnings.vue'),
        meta:{
            needLogin: true
        }  
    }             
           
]

export default meRoute