import userInfo from '@/views/page/user/index.vue';
import fans from '@/views/page/user/fans.vue';
import like from '@/views/page/user/like.vue';
import report from '@/views/page/user/report.vue';

const userRoute = [
    {
        path: '/userInfo',
        name: 'userInfo',
        component: userInfo,
     
    },
    {
        path: '/user/fans',
        name: 'fans',
        component: fans 
    },
    {
        path: '/user/like',
        name: 'like',
        component: like 
    },
    {
        path: '/user/report',
        name: 'userReport',
        component: report 
    },
    

]

export default userRoute