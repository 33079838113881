import wechat from '@/views/wechat/index.vue';
import liuhe from '@/views/wechat/liuhe.vue';

const wechatRoute = [
    {
        path: '/wechat',
        name: 'wechat',
        component: wechat,
        meta:{
            needLogin: true
        }
      
    },
    {
        path: '/wechat/liuhe',
        name: 'liuhe',
        component: liuhe,
        meta:{
            needLogin: true
        }
      
    },

    

]

export default wechatRoute