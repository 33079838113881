import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../assets/image/link_r.png';

import { useRouter } from 'vue-router';
import { ref, reactive, onMounted, onUnmounted } from 'vue';
const _withScopeId = n => (_pushScopeId("data-v-01674a7f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "tk-head-nav flex-between"
};
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_3 = {
  class: "tk-head-info"
};
export default {
  __name: 'headNav',
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: Boolean,
      default: true
    },
    video: {
      type: String,
      default: ''
    },
    top: {
      type: Boolean,
      default: false
    },
    resize: {
      type: Boolean,
      default: false
    }
  },
  emits: ['morelottery', 'returnUp'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const route = useRouter();
    let scrollTabs = ref(null);
    let scroolData = reactive({
      offsetTop: 0
    });
    const resizeObserver = new ResizeObserver(entries => {
      // 处理大小变化的回调函数
      for (const entry of entries) {
        scroolData.offsetTop = entry.target.clientHeight;
        // 在这里执行你想要的操作，比如更新数据或触发其他事件
      }
    });

    let morelottery = () => {
      emits('morelottery');
    };
    onMounted(() => {
      // 获取 DOM 元素
      scroolData.offsetTop = scrollTabs.value?.clientHeight;
      if (props.resize) {
        resizeObserver.observe(scrollTabs.value);
      }
    });
    onUnmounted(() => {
      if (props.resize) {
        resizeObserver.unobserve(scrollTabs.value);
      }
    });
    let returnUp = () => {
      //返回上一页
      if (props.video != '') {
        emits('returnUp');
      } else {
        callNativeApp('back');
        emits('returnUp');
        route.back();
      }
    };
    let callNativeApp = back => {
      try {
        // 從JS傳送資訊到App裡設定的callbackHandler。
        webkit.messageHandlers.callbackHandler.postMessage(back);
      } catch (err) {
        // 如果找不到定義的callbackHandler則回傳錯誤訊息。
      }
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: "tk-main-head-nav",
        ref_key: "scrollTabs",
        ref: scrollTabs
      }, [__props.type ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "tk-head-return",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(returnUp) && _unref(returnUp)(...args))
      }, [_createVNode(_component_van_icon, {
        style: {
          "font-size": "20px"
        },
        name: "arrow-left"
      })]), _createElementVNode("div", {
        class: "tk-head-title",
        onClick: _cache[1] || (_cache[1] = (...args) => _unref(morelottery) && _unref(morelottery)(...args))
      }, [_createElementVNode("span", null, _toDisplayString(__props.title), 1), __props.top ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "iocn")])])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "tkCont")], 512), _createElementVNode("div", {
        style: _normalizeStyle({
          'height': _unref(scroolData).offsetTop + 'px'
        })
      }, null, 4)], 64);
    };
  }
};