import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

import { reactive, toRefs } from 'vue';
const _withScopeId = n => (_pushScopeId("data-v-f7be5046"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-major-nav"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "tk-link"
};
export default {
  __name: 'genre',
  props: {
    dataNav: {
      type: Array,
      default: [{
        name: '波色',
        id: 0
      }, {
        name: '生肖',
        id: 1
      }, {
        name: '五行',
        id: 2
      }, {
        name: '家禽野兽',
        id: 3
      }]
    }
  },
  emits: 'majorNav',
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    let data = reactive({
      left: 0,
      indexActive: 0
    });
    let {
      left,
      indexActive
    } = toRefs(data);
    let majoNav = (index, id) => {
      let num = props.dataNav.length;
      data.left = 100 / num * index;
      data.indexActive = index;
      emits('majorNav', id);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", {
        ref: "majornav",
        style: _normalizeStyle({
          'grid-template-columns': `repeat(${__props.dataNav.length},1fr)`
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.dataNav, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => _unref(majoNav)(index, item.id)
        }, _toDisplayString(item.name), 9, _hoisted_2);
      }), 128))], 4), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        class: "link",
        style: _normalizeStyle({
          'left': _unref(left) + '%',
          'width': `${100 / __props.dataNav.length}%`
        })
      }, null, 4)])]);
    };
  }
};