import master from '@/views/page/master/index.vue';
import search from '@/views/page/master/search.vue';
import release from '@/views/page/master/release.vue';
import masterDetails from '@/views/page/master/details.vue';

const masterRoute = [
    {
        path: '/master',
        name: 'master',
        component: master,
        meta: {
            keepAlive: true,
          },
    },
    {
        path: '/master/search',
        name: 'masterSearch',
        component: search,
       
    },
    {
        path: '/master/release',
        name: 'masterRelease',
        component: release,
        meta:{
            needLogin: true,
        }
   
    },
    {
        path: '/master/details',
        name: 'masterDetails',
        component: masterDetails,
  
    },
    
]

export default masterRoute