import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d647cb7a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-main-fans"
};
const _hoisted_2 = {
  class: "tk-fans"
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["src"];
const _hoisted_6 = ["onClick"];
const _hoisted_7 = ["onClick"];
import search from '@/components/page/search.vue';
import Dialog from '@/components/mode/message';
import { toRefs } from 'vue';
import { getFocus } from '@/utils/https/me';
import { setFocus } from '@/utils/https/https';

import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'follow',
  setup(__props) {
    let data = reactive({
      focusInfo: [],
      page: 1,
      keywords: ''
    });
    let {
      focusInfo,
      http
    } = toRefs(data);
    const router = useRouter();
    //接口请求区域
    let getFocuss = (page, keywords = '') => {
      getFocus({
        page: page,
        keywords: keywords
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        if (datas == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.focusInfo.push(...datas);
        }
      });
    };
    let focus = id => {
      //关注
      setFocus({
        id: id
      }).then(res => {
        if (res.status != '400') {} else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      getFocuss(data.page, data.keywords);
      data.page++;
    };
    let onSubmit = item => {
      //搜索
      data.page = 1;
      data.keywords = item;
      data.focusInfo.length = 0;
      getFocuss(data.page, data.keywords);
    };
    let cancel = (item, index) => {
      //取消关注
      Dialog('取消成功', '#07c160');
      focus(item);
      data.focusInfo.forEach((element, i) => {
        if (i == index) {
          data.focusInfo.splice(index, 1);
        }
      });
    };
    let userInfo = item => {
      router.push({
        path: '/userInfo',
        query: {
          id: item.user.id
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "我的关注"
      }, {
        tkCont: _withCtx(() => [_createVNode(search, {
          returns: false,
          onOnSubmit: _unref(onSubmit)
        }, null, 8, ["onOnSubmit"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_unref(focusInfo).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createElementBlock("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(focusInfo), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("div", {
            class: "tk-fans-picture",
            onClick: $event => _unref(userInfo)(item)
          }, [_createElementVNode("img", {
            src: item.user.avatar == '' ? require('@/assets/image/logo.jpg') : _ctx.$httpsUrl + item.user.avatar,
            alt: ""
          }, null, 8, _hoisted_5)], 8, _hoisted_4), _createElementVNode("div", {
            class: "fans-name",
            onClick: $event => _unref(userInfo)(item)
          }, _toDisplayString(item.user.nickname), 9, _hoisted_6), _createElementVNode("div", {
            class: "botton",
            onClick: $event => _unref(cancel)(item.user.id, index)
          }, "取消关注", 8, _hoisted_7)]);
        }), 128))]))])]),
        _: 1
      }, 8, ["loading", "finished"])])], 64);
    };
  }
};