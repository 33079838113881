
import serach from '@/views/page/serach.vue';
import notice from '@/views/page/notice.vue';
import image from '@/views/page/image.vue';
import seriesImage from '@/views/page/seriesImage.vue';

import history from '@/views/page/lottery/history.vue';
import playback from '@/views/page/lottery/playback.vue';
import playDesc from '@/views/page/lottery/playDesc.vue';
import historyDesc from '@/views/page/lottery/historyDesc.vue';
import materials from '@/views/page/materials/index.vue'
import list from '@/views/page/materials/list.vue'
import search from '@/views/page/materials/search.vue'
import details from '@/views/page/materials/details.vue'
import commentsc from '@/views/page/materials/comment.vue'
import materialsHistory from '@/views/page/materials/history.vue';
const pageRoute = [

    {
        path: '/serach',
        name: 'serach',
        component: serach,
        meta: {
            keepAlive: true,
            top:true
        },
    },
    {
        path: '/notice',
        name: 'notice',
        component: notice
        
    },
    {
        path: '/tuku',
        name: 'image',
        component: image
        
    },
    {
        path: '/xilie',
        name: 'seriesImage',
        component: seriesImage
        
    },
    {
        path: '/lottery',
        name: 'lottery',
        meta: {
            keepAlive: true,
          },
        component: ()=>import("@/views/page/lottery/index.vue"),
    },
    {
        path: '/lottery/history',
        name: 'history',
        component: history
    },
    {
        path: '/lottery/playback',
        name: 'playback',
        component: playback,
        meta:{
            scrollTo:true
        }
    },
    {
        path: '/lottery/playback/details',
        name: 'playDesc',
        component: playDesc
    },
    {
        path: '/lottery/history/details',
        name: 'historyDesc',
        component: historyDesc
    },
    
    
    

    {
        path: '/materials',
        name: 'materials',
        component: materials,  
        meta: {
            keepAlive: true,
            top:true,
        },
    },
    {
        path: '/materials/list',
        name: 'list',
        component: list,
        meta: {
            keepAlive: true,
            top:true
        },
    },
    {
        path: '/materials/list/search',
        name: 'search',
        component: search,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/materials/list/details',
        name: 'details',
        component: details,
        meta: {
            keepAlive: true,
            top:true
        },
       
    },
    {
        path: '/materials/list/comment',
        name: 'materialscomments',
        component: commentsc,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/materials/list/history',
        name: 'materialsHistory',
        component: materialsHistory,
        meta:{
            needLogin: true
        }
    },
    {
        path: '/activity/index',
        name: 'activityss',
        component: () => import('../views/activity/index2.vue'),
    },
    {
        path: '/game/index',
        name: 'game',
        component: () => import('../views/page/game/dz.vue'),
    },
    {
        path: '/game/list',
        name: 'gameDetails',
        component: () => import('../views/page/game/details.vue'),
    },
    {
        path: '/game/pg',
        name: 'pgs',
        component: () => import('../views/page/game/pg.vue'),
    },
    {
        path: '/game/pg/index',
        name: 'pgw',
        component: () => import('../views/page/game/pgtu.vue'),
    },

      
    
    
]


export default pageRoute