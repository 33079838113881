import serve from './serve'

export function recommend(params){//获取推荐
    return serve({
        url:'v1/liuhe/recommend',
        method :'get',
        params:params,
    })
}

export function history(params){//获取历史记录
    return serve({
        url:'v1/liuhe/history',
        method :'get',
        params:params,
    })
}

export function numbers(params){//获取历史记录
    return serve({
        url:'v1/liuhe/numbers',
        method :'get',
        params:params,
    })
}

export function open_date(params){//获取开奖日期
    return serve({
        url:'v1/liuhe/open_date',
        method :'get',
        params:params,
    })
}

export function backVideo(params){//获取回放
    return serve({
        url:'v1/liuhe/video',
        method :'get',
        params:params,
    })
}

