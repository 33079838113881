import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-68d74814"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-notice-announcement"
};
const _hoisted_2 = {
  class: "tk-announcement"
};
const _hoisted_3 = {
  class: "tk-notice-title flex-between"
};
const _hoisted_4 = {
  class: "tk-title-l"
};
const _hoisted_5 = {
  class: "tk-notice-content"
};
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = {
  class: "tk-tiems"
};
const _hoisted_8 = {
  class: "tk-notice-message"
};
const _hoisted_9 = {
  class: "tk-message-head flex-between"
};
const _hoisted_10 = {
  class: "logo"
};
const _hoisted_11 = ["src"];
const _hoisted_12 = {
  class: "tk-head-info"
};
const _hoisted_13 = {
  class: "name"
};
const _hoisted_14 = {
  class: "tk-msg-tiems"
};
const _hoisted_15 = ["innerHTML"];
import { onBeforeMount, reactive, toRefs } from "vue";
import { useStore } from 'vuex';
import { getMessage, setMessage } from '@/utils/https/page';

import { ref } from 'vue';
export default {
  __name: 'notice',
  setup(__props) {
    let data = reactive({
      activeIndex: 1,
      page: 1,
      //分页
      announcement: [],
      notification: [],
      websiteName: JSON.parse(localStorage.getItem('config')) == null ? {} : JSON.parse(localStorage.getItem('config'))
    });
    let {
      announcement,
      notification,
      websiteName
    } = toRefs(data);
    const store = useStore(); //获取vuex

    onBeforeMount(() => {
      // getNotice(1,data.page)
    });
    //接口请求区域
    let getNotice = (type, page) => {
      getMessage({
        type: type,
        page: page
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          if (type == 2) {
            data.notification.push(...datas);
          } else {
            datas.forEach(element => {
              element.valid_date_start = replaceStr1(element.valid_date_start);
              element.valid_date_end = replaceStr1(element.valid_date_end);
              if (tiems('') - tiems(element.valid_date_end) <= 0 <= 0) {
                element.status = 0;
              } else {
                element.status = 1;
              }
            });
            data.announcement.push(...datas);
          }
        }
      });
    };
    let setMessages = () => {
      setMessage().then(res => {
        if (res.data.status != 40000) {
          store.commit('msgadd', 0);
        }
      });
    };
    let tiems = datas => {
      var data;
      if (datas == '') {
        data = new Date().getTime();
      } else {
        data = new Date(datas).getTime();
      }
      return data;
    };
    const replaceStr1 = str => {
      const strAry = str.split('-');
      return strAry.join('/');
    };
    //功能区域
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const onLoad = () => {
      getNotice(data.activeIndex, data.page);
      data.page++;
    };
    // const onRefresh = () => {
    //       // 清空列表数据
    //       data.page =1
    //       data.announcement.length =0
    //       data.notification.length =0
    //       finished.value = false;
    //       // 重新加载数据
    //       // 将 loading 设置为 true，表示处于加载状态
    //       loading.value = true;
    //       onLoad();
    // };

    let {
      activeIndex
    } = toRefs(data);
    let principalTans = index => {
      if (index == 1) {
        setMessages();
      }
      data.announcement.length = 0;
      data.notification.length = 0;
      data.page = 1;
      data.activeIndex = parseInt(index + 1);
      finished.value = false;
      loading.value = true;
      onLoad();
    };
    return (_ctx, _cache) => {
      const _component_navTabsVue = _resolveComponent("navTabsVue");
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      const _component_majorBack = _resolveComponent("majorBack");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "公告"
      }, {
        tkCont: _withCtx(() => [_createVNode(_component_navTabsVue, {
          onPrincipalTans: _unref(principalTans)
        }, null, 8, ["onPrincipalTans"])]),
        _: 1
      }), _createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("ul", null, [_withDirectives(_createElementVNode("div", null, [_unref(announcement).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(announcement), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.title), 1), _createElementVNode("div", {
            class: _normalizeClass(item.status ? 'tk-title-r' : 'active tk-title-r')
          }, _toDisplayString(item.status ? '未结束' : '已结束'), 3)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
            class: "tk-content",
            innerHTML: item.content
          }, null, 8, _hoisted_6)]), _createElementVNode("div", _hoisted_7, "有效时间：" + _toDisplayString(item.valid_date_start) + "-" + _toDisplayString(item.valid_date_end), 1)]);
        }), 128))], 512), [[_vShow, _unref(activeIndex) == 1]]), _withDirectives(_createElementVNode("div", null, [_unref(notification).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(notification), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("img", {
            src: _ctx.$httpsUrl + _unref(websiteName).logo,
            alt: ""
          }, null, 8, _hoisted_11)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_14, _toDisplayString(item.created_at), 1)])]), _createElementVNode("div", {
            class: "tk-msg-content",
            innerHTML: item.content
          }, null, 8, _hoisted_15)])]);
        }), 128))], 512), [[_vShow, _unref(activeIndex) == 2]])])])])]),
        _: 1
      }, 8, ["loading", "finished"]), _createVNode(_component_majorBack)], 64);
    };
  }
};