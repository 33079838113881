import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6010e7dc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-main-materials"
};
const _hoisted_2 = ["onClick"];
import search from '@/components/page/search.vue';
import { listCorpusType } from '@/utils/https/materials';

import { useRouter, useRoute } from 'vue-router';
import { onBeforeMount, reactive, toRefs } from 'vue';
export default {
  __name: 'seriesImage',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    onBeforeMount(() => {
      let lottery = route.query.lottery; //获取彩种
      data.lottery = lottery;
      listCorpus();
    });
    let data = reactive({
      listCorpusArray: [],
      oldListCorpusArray: [],
      show: false,
      lottery: localStorage.getItem('lotteryType')
    });
    let {
      listCorpusArray,
      columns,
      show
    } = toRefs(data);
    let listCorpus = () => {
      //请求页面数据
      let id = localStorage.getItem('lotteryType');
      let articleData = JSON.parse(sessionStorage.getItem(`picSeriesLists_${id}`));
      data.listCorpusArray.push(...articleData);
      data.oldListCorpusArray.push(...articleData);
    };
    let showYear = () => {
      data.show = !data.show;
    };
    let onSubmit = item => {
      if (item.length == 0) {
        data.listCorpusArray = data.oldListCorpusArray;
      }
      data.listCorpusArray = fuzzyQuery(data.listCorpusArray, item);
    };
    /**
    * 使用indexof方法实现模糊查询
    * @param  {Array}  list     进行查询的数组
    * @param  {String} keyWord  查询的关键词
    * @return {Array}           查询的结果
    */
    let fuzzyQuery = (list, keyWord) => {
      var arr = [];
      for (var i = 0; i < list.length; i++) {
        if (list[i].name.indexOf(keyWord) >= 0) {
          arr.push(list[i]);
        }
      }
      return arr;
    };
    let majorDesc = item => {
      //跳转详情

      router.push({
        path: `/tuku`,
        query: {
          id: item.id,
          name: item.name,
          lottery: data.lottery
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_majorBack = _resolveComponent("majorBack");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "图库类型"
      }, {
        tkCont: _withCtx(() => [_createVNode(search, {
          returns: false,
          onOnSubmit: _unref(onSubmit),
          placeholder: "请输入要搜索的内容"
        }, null, 8, ["onOnSubmit"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_unref(listCorpusArray).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
        key: 0
      })) : _createCommentVNode("", true), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(listCorpusArray), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          onClick: $event => _unref(majorDesc)(item),
          key: index
        }, _toDisplayString(item.name), 9, _hoisted_2);
      }), 128))])]), _createVNode(_component_majorBack)], 64);
    };
  }
};