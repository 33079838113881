import serve from './serve'
export function picture(params){//图解详情
    return serve({
        url:'v1/picture/detail',
        method :'get',
        params:params,
    })
}
export function pictureVote(params){//图解投票
    return serve({
        url:'v1/picture/vote',
        method :'post',
        data:params,
    })
}
export function pictureIssues(params){//图解投票
    return serve({
        url:'v1/picture/issues',
        method :'get',
        data:params,
    })
}

export function pictureFollow(params){//图解点赞
    return serve({
        url:'v1/picture/follow',
        method :'post',
        data:params,
    })
}
export function pictureCollect(params){//图解收藏
    return serve({
        url:'v1/picture/collect',
        method :'post',
        data:params,
    })
}

export function diagramList(params){//图解
    return serve({
        url:'v1/diagram/list',
        method :'get',
        params:params,
    })
}

export function diagramCreate(params){//添加图解
    return serve({
        url:'v1/diagram/create',
        method :'post',
        data:params,
    })
}

export function forecastList(params){//竞猜列表
    return serve({
        url:'v1/forecast/list',
        method :'get',
        params:params,
    })
}

export function diagramDetail(params){//添加图解
    return serve({
        url:'v1/diagram/detail',
        method :'get',
        params:params,
    })
}

export function diagramFollow(params){//点赞图解
    return serve({
        url:'v1/diagram/follow',
        method :'post',
        data:params,
    })
}

export function forecastDetail(params){//竞猜详情
    return serve({
        url:'v1/forecast/detail',
        method :'get',
        params:params,
    })
}
export function forecastFollow(params){//竞猜详情
    return serve({
        url:'v1/forecast/follow',
        method :'post',
        data:params,
    })
}

export function forecastJoin(params){//参与竞猜
    return serve({
        url:'v1/forecast/join',
        method :'get',
        params:params,
    })
}

export function forecastCreate(params){//上传竞猜
    return serve({
        url:'v1/forecast/create',
        method :'post',
        data:params,
    })
}


export function pictureRecommend(params){//图片推荐
    return serve({
        url:'v1/picture/recommend',
        method :'get',
        params:params,
    })
}