import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6e64dac8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-main-comment"
};
const _hoisted_2 = {
  class: "tk-comment"
};
const _hoisted_3 = {
  class: "tk-textarea"
};
const _hoisted_4 = {
  class: "tk-file"
};
const _hoisted_5 = {
  class: "file-shape"
};
const _hoisted_6 = {
  class: "file-upload"
};
const _hoisted_7 = {
  class: "link"
};
const _hoisted_8 = {
  class: "tk-comment-botton"
};
import uploadImage from '@/components/uploadImage.vue';
import { uploadFile } from '@/utils/https/https';
import { complaintAdd } from '@/utils/https/user';
import Dialog from '@/components/mode/message';

import { reactive, toRefs, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  __name: 'report',
  setup(__props) {
    let data = reactive({
      fileList: [],
      content: '',
      image: [],
      typeId: '',
      from: {
        content: '',
        id: '',
        images: [],
        type: '',
        corpusTypeId: ''
      }
    });
    let {
      content
    } = toRefs(data);
    let route = useRoute();
    let router = useRouter();
    onBeforeMount(() => {
      data.from.type = route.query.typeId;
      data.from.id = route.query.id;
      data.from.corpusTypeId = route.query.corpusTypeId;
    });
    let addcomment = from => {
      complaintAdd(from).then(res => {
        if (res.status != '400') {
          router.back();
          from.content = '';
          from.images.length = 0;
          Dialog(res.data.message, '#07c160');
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let uploadFun = array => {
      data.fileList = array;
    };
    let submits = async () => {
      if (data.content.length == 0) return false;
      if (data.fileList.length != 0) {
        let res = uploadFile(data.fileList); //图片上传接口 先上传 在提交
        await res.then(datac => {
          if (datac.data.status == 40000) {
            Dialog(datac.data.message, '#07c160');
            return false;
          }
          let datas = datac.data.data.url;
          data.from.images = datas;
        });
      }
      data.from.content = data.content;
      addcomment(data.from);
    };
    let changeFile = e => {};
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_botton = _resolveComponent("botton");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "举报"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(content) ? content.value = $event : content = $event),
        placeholder: "想说点什么...",
        rows: "10"
      }, null, 512), [[_vModelText, _unref(content)]])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(uploadImage, {
        onUploadFun: _unref(uploadFun)
      }, null, 8, ["onUploadFun"])])]), _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(content).length) + "/2000", 1)])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_botton, {
        onClick: _unref(submits),
        bgcolor: _unref(content).length == 0 ? 'rgba(3, 159, 76, 0.4' : '#07c160',
        name: "发布"
      }, null, 8, ["onClick", "bgcolor"])])])], 64);
    };
  }
};