import { createApp } from 'vue'
import Dialog from '@/components/mode/message.vue'

const createDialog = (message,color) => {
    const mountNode = document.createElement('div')
    const Instance = createApp(Dialog, {
        show: true,
        color,
        message,
    })
   let c =  setInterval(()=>{
     
        Instance.unmount(mountNode); 
        document.body.removeChild(mountNode);
        clearInterval(c)
    },3000)
    document.body.appendChild(mountNode)
    Instance.mount(mountNode)
}

export default createDialog
