import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5c8c3318"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-main-notive flex-between"
};
const _hoisted_2 = {
  class: "tk-notive-icon"
};
import { onBeforeMount, ref, watch } from "vue";

import { useRouter } from 'vue-router';
export default {
  __name: 'notive',
  props: {
    notiveInfo: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    const str = ref('');
    let notiveInfo = ref('');
    onBeforeMount(() => {
      str.value = localStorage.getItem('notice');
    });
    const route = useRouter();
    let notiveDesc = () => {
      route.push({
        path: `/notice`
      });
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_notice_bar = _resolveComponent("van-notice-bar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_icon, {
        name: "volume"
      })]), _createElementVNode("div", {
        class: "tk-notive-content",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(notiveDesc) && _unref(notiveDesc)(...args))
      }, [_createVNode(_component_van_notice_bar, {
        scrollable: "",
        text: str.value
      }, null, 8, ["text"])])]);
    };
  }
};