import serve from './serve'

export function guess(params){//幽默猜测期数
    return serve({
        url:'v1/humorous/guess',
        method :'get',
        params:params,
    })
}
export function guessDetail(params){//幽默猜测期数
    return serve({
        url:'v1/humorous/detail',
        method :'get',
        params:params,
    })
}
export function guessFollow(params){//幽默猜测点赞
    return serve({
        url:'v1/humorous/follow',
        method :'post',
        data:params,
    })
}
export function guessCollect(params){//幽默猜测收藏
    return serve({
        url:'v1/humorous/collect',
        method :'post',
        data:params,
    })
}
export function guessVote(params){//幽默猜测投票
    return serve({
        url:'v1/humorous/vote',
        method :'post',
        data:params,
    })
}






