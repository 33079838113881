import guess from '@/views/page/guess/index.vue';

import period from '@/views/page/guess/period.vue';
const guessRoute = [
    {
        path: '/guess',
        name: 'guess',
        component: guess,
        meta: {
            keepAlive: true,
          },
   
    },
    {
        path: '/guess/period',
        name: 'period',
        component: period
    }
]

export default guessRoute