import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, unref as _unref, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4a76fdd0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "tk-main-lottery"
};
const _hoisted_2 = {
  class: "flex-between tk-lottery-qishu s"
};
const _hoisted_3 = {
  class: "tk-lottery-qi"
};
const _hoisted_4 = {
  class: "tk-lottery-box"
};
const _hoisted_5 = {
  class: "sx"
};
const _hoisted_6 = {
  key: 1,
  class: "tk-main-lottery"
};
const _hoisted_7 = {
  class: "tk-lottery-qishu flex-between"
};
const _hoisted_8 = {
  class: "tk-lottery-qi"
};
const _hoisted_9 = {
  class: "djs"
};
const _hoisted_10 = {
  class: "tk-lottery-box"
};
const _hoisted_11 = {
  key: 0
};
const _hoisted_12 = {
  key: 1,
  class: "span"
};
const _hoisted_13 = {
  class: "sx"
};
const _hoisted_14 = {
  class: "tk-lottery-tiems"
};
import { nextTick, onBeforeMount, reactive, ref, watch } from 'vue';
import { lotteryList } from '@/utils/https/https';
import { numbers } from '@/utils/https/lottery';
import { useStore } from 'vuex';
import axios from 'axios';

import { useRouter } from 'vue-router';
import { computed, onActivated, onDeactivated } from 'vue';
export default {
  __name: 'lottery',
  props: {
    types: {
      type: Boolean,
      default: true
    },
    lottery: {
      type: String,
      default: '2'
    },
    data: {
      type: Object,
      default: {
        id: 30,
        year: "2020",
        issue: "030",
        lotteryType: 1,
        lotteryTime: "2020-12-29",
        lotteryWeek: "",
        number: "11 39 10 24 41 42 22",
        attr_sx: "虎 狗 兔 牛 猴 羊 兔",
        attr_wx: "木 水 木 水 木 火 土",
        attr_bs: "3 3 2 1 2 2 3",
        number_attr: [{
          color: 3,
          number: "11",
          shengXiao: "虎",
          wuXing: "木"
        }, {
          color: 3,
          number: "39",
          shengXiao: "狗",
          wuXing: "水"
        }, {
          color: 2,
          number: "10",
          shengXiao: "兔",
          wuXing: "木"
        }, {
          color: 1,
          number: "24",
          shengXiao: "牛",
          wuXing: "水"
        }, {
          color: 2,
          number: "41",
          shengXiao: "猴",
          wuXing: "木"
        }, {
          color: 2,
          number: "42",
          shengXiao: "羊",
          wuXing: "火"
        }, {
          color: 3,
          number: "22",
          shengXiao: "兔",
          wuXing: "土"
        }],
        te_attr: {
          color: 3,
          number: "22",
          shengXiao: "兔",
          wuXing: "土",
          oddEven: "双",
          bigSmall: "小"
        },
        total_attr: {
          total: 189,
          oddEven: "单",
          bigSmall: "大"
        },
        open_time: null,
        video_url: null,
        created_at: "2023-05-12 13:01:38",
        updated_at: "",
        nextData: {
          lotteryTime: "2023-06-22",
          lotteryWeek: "四"
        }
      }
    }
  },
  setup(__props, {
    expose: __expose
  }) {
    const propr = __props;
    const router = useRouter();
    let cl;
    let handleNext = () => {
      router.push({
        path: '/lottery/history',
        query: {
          lottery: propr.lottery
        }
      });
    };
    var lotteryAaayVuex = [];
    var cler;
    // let tw = lotteryTwData
    // let xg =  lotteryXgData;
    // let old_am =  lottery48AmData
    // let xjp = lotteryXjpData
    // let am =  lotteryAmData
    const $store = useStore(); //获取vuex
    const configInfo = computed(() => {
      if ($store.state.config != null) {
        return $store.state.config;
      } else {
        return {
          cloud_url: 'https://lty-s.s3.ap-east-1.amazonaws.com/49_tk/'
        };
      }
      ;
    });
    let numberList = ref({
      Qi: '0',
      Data: [{
        color: 0,
        number: '0',
        sx: '',
        nim: ''
      }, {
        color: 0,
        number: '0',
        sx: '',
        nim: ''
      }, {
        color: 0,
        number: '0',
        sx: '',
        nim: ''
      }, {
        color: 0,
        number: '0',
        sx: '',
        nim: ''
      }, {
        color: 0,
        number: '0',
        sx: '',
        nim: ''
      }, {
        color: 0,
        number: '0',
        sx: '',
        nim: ''
      }, {
        color: 0,
        number: '0',
        sx: '',
        nim: ''
      }],
      Nq: '0',
      Moon: 0,
      Day: 0,
      Time: 0,
      date: ''
    });
    let databs = reactive({
      shengxiaoList: [{
        name: "金",
        list: [{
          color: 1,
          number: "01"
        }, {
          color: 1,
          number: "02"
        }, {
          color: 2,
          number: "09"
        }, {
          color: 2,
          number: "10"
        }, {
          color: 1,
          number: "23"
        }, {
          color: 1,
          number: "24"
        }, {
          color: 2,
          number: "31"
        }, {
          color: 3,
          number: "32"
        }, {
          color: 3,
          number: "39"
        }, {
          color: 1,
          number: "40"
        }]
      }, {
        name: "木",
        list: [{
          color: 3,
          number: "05"
        }, {
          color: 3,
          number: "06"
        }, {
          color: 1,
          number: "13"
        }, {
          color: 2,
          number: "14"
        }, {
          color: 3,
          number: "21"
        }, {
          color: 3,
          number: "22"
        }, {
          color: 1,
          number: "35"
        }, {
          color: 2,
          number: "36"
        }, {
          color: 3,
          number: "43"
        }, {
          color: 3,
          number: "44"
        }]
      }, {
        name: "水",
        list: [{
          color: 3,
          number: "11"
        }, {
          color: 1,
          number: "12"
        }, {
          color: 1,
          number: "19"
        }, {
          color: 2,
          number: "20"
        }, {
          color: 3,
          number: "27"
        }, {
          color: 3,
          number: "28"
        }, {
          color: 2,
          number: "41"
        }, {
          color: 2,
          number: "42"
        }, {
          color: 3,
          number: "49"
        }]
      }, {
        name: "火",
        list: [{
          color: 1,
          number: "07"
        }, {
          color: 1,
          number: "08"
        }, {
          color: 2,
          number: "15"
        }, {
          color: 3,
          number: "16"
        }, {
          color: 1,
          number: "29"
        }, {
          color: 1,
          number: "30"
        }, {
          color: 2,
          number: "37"
        }, {
          color: 3,
          number: "38"
        }, {
          color: 1,
          number: "45"
        }, {
          color: 1,
          number: "46"
        }]
      }, {
        name: "土",
        list: [{
          color: 2,
          number: "03"
        }, {
          color: 2,
          number: "04"
        }, {
          color: 3,
          number: "17"
        }, {
          color: 1,
          number: "18"
        }, {
          color: 2,
          number: "25"
        }, {
          color: 2,
          number: "26"
        }, {
          color: 3,
          number: "33"
        }, {
          color: 1,
          number: "34"
        }, {
          color: 2,
          number: "47"
        }, {
          color: 2,
          number: "48"
        }]
      }],
      wuxingList: [{
        name: "兔",
        list: [{
          "color": 1,
          "number": "01"
        }, {
          "color": 1,
          "number": "13"
        }, {
          "color": 2,
          "number": "25"
        }, {
          "color": 2,
          "number": "37"
        }, {
          "color": 3,
          "number": "49"
        }]
      }, {
        name: "牛",
        list: [{
          "color": 2,
          "number": "03"
        }, {
          "color": 2,
          "number": "15"
        }, {
          "color": 3,
          "number": "27"
        }, {
          "color": 3,
          "number": "39"
        }]
      }, {
        name: "狗",
        list: [{
          "color": 3,
          "number": "06"
        }, {
          "color": 1,
          "number": "18"
        }, {
          "color": 1,
          "number": "30"
        }, {
          "color": 2,
          "number": "42"
        }]
      }, {
        name: "猪",
        list: [{
          "color": 3,
          "number": "05"
        }, {
          "color": 3,
          "number": "17"
        }, {
          "color": 1,
          "number": "29"
        }, {
          "color": 2,
          "number": "41"
        }]
      }, {
        name: "猴",
        list: [{
          "color": 1,
          "number": "08"
        }, {
          "color": 2,
          "number": "20"
        }, {
          "color": 3,
          "number": "32"
        }, {
          "color": 3,
          "number": "44"
        }]
      }, {
        name: "羊",
        list: [{
          "color": 2,
          "number": "09"
        }, {
          "color": 3,
          "number": "21"
        }, {
          "color": 3,
          "number": "33"
        }, {
          "color": 1,
          "number": "45"
        }]
      }, {
        name: "虎",
        list: [{
          "color": 1,
          "number": "02"
        }, {
          "color": 2,
          "number": "14"
        }, {
          "color": 2,
          "number": "26"
        }, {
          "color": 3,
          "number": "38"
        }]
      }, {
        name: "蛇",
        list: [{
          "color": 3,
          "number": "11"
        }, {
          "color": 1,
          "number": "23"
        }, {
          "color": 1,
          "number": "35"
        }, {
          "color": 2,
          "number": "47"
        }]
      }, {
        name: "马",
        list: [{
          "color": 2,
          "number": "10"
        }, {
          "color": 3,
          "number": "22"
        }, {
          "color": 1,
          "number": "34"
        }, {
          "color": 1,
          "number": "46"
        }]
      }, {
        name: "鸡",
        list: [{
          "color": 1,
          "number": "07"
        }, {
          "color": 1,
          "number": "19"
        }, {
          "color": 2,
          "number": "31"
        }, {
          "color": 3,
          "number": "43"
        }]
      }, {
        name: "鼠",
        list: [{
          "color": 2,
          "number": "04"
        }, {
          "color": 3,
          "number": "16"
        }, {
          "color": 3,
          "number": "28"
        }, {
          "color": 1,
          "number": "40"
        }]
      }, {
        name: "龙",
        list: [{
          "color": 1,
          "number": "12"
        }, {
          "color": 1,
          "number": "24"
        }, {
          "color": 2,
          "number": "36"
        }, {
          "color": 2,
          "number": "48"
        }]
      }],
      djs: '00:00:00'
    });
    let newdatas = reactive({
      shengxiaoList: [],
      wuxingList: []
    });
    var tw = configInfo.value.cloud_url + 'open_lottery/v_tw.json';
    var xg = configInfo.value.cloud_url + 'open_lottery/v_xg.json';
    var old_am = configInfo.value.cloud_url + 'open_lottery/v_am_plus.json';
    var xjp = configInfo.value.cloud_url + 'open_lottery/v_xjp.json';
    var am = configInfo.value.cloud_url + 'open_lottery/v_am.json';
    var klb = configInfo.value.cloud_url + 'open_lottery/v_fckl8.json';
    var oldam = configInfo.value.cloud_url + 'open_lottery/v_oldam.json';
    let numberss = (lottery, year) => {
      let lotteryNumber = $store.state.openLotteryList;
      // let ress
      // if(lotteryNumber.length == 0){
      //      ress = -1
      // }else{
      //      ress = lotteryNumber.findIndex((ev) => {
      //         return ev.lottery == lottery;
      //     });
      // }
      // if(lotteryNumber.length !=0 && ress!=-1){
      // }else{
      let datass = lotteryNumber;
      numberList.value.length = 0;
      datass.forEach(item => {
        if (item.lottery == lottery) {
          numberList.value = item.numberList;
        }
      });
      let timestamp = new Date().valueOf();
      if (lottery == 1) {
        lotteryGet(`${xg}?${timestamp}`, lottery);
      }
      if (lottery == 2) {
        lotteryGet(`${old_am}?${timestamp}`, lottery);
      }
      if (lottery == 3) {
        lotteryGet(`${tw}?${timestamp}`, lottery);
      }
      if (lottery == 4) {
        lotteryGet(`${xjp}?${timestamp}`, lottery);
      }
      if (lottery == 5) {
        lotteryGet(`${am}?${timestamp}`, lottery);
      }
      if (lottery == 6) {
        lotteryGet(`${klb}?${timestamp}`, lottery);
      }
      if (lottery == 7) {
        lotteryGet(`${oldam}?${timestamp}`, lottery);
      }
    };

    // }
    onBeforeMount(() => {
      tw = configInfo.value.cloud_url + 'open_lottery/v_tw.json';
      xg = configInfo.value.cloud_url + 'open_lottery/v_xg.json';
      old_am = configInfo.value.cloud_url + 'open_lottery/v_am_plus.json';
      xjp = configInfo.value.cloud_url + 'open_lottery/v_xjp.json';
      am = configInfo.value.cloud_url + 'open_lottery/v_am.json';
      oldam = configInfo.value.cloud_url + 'open_lottery/v_oldam.json';
      numberss(propr.lottery, '2023');
      // clearInterval(cler);
      clearInterval(cl);
      cl = setInterval(() => {
        openLotteryTiems();
      }, 1000);
    });
    onActivated(async () => {
      tw = configInfo.value.cloud_url + 'open_lottery/v_tw.json';
      xg = configInfo.value.cloud_url + 'open_lottery/v_xg.json';
      old_am = configInfo.value.cloud_url + 'open_lottery/v_am_plus.json';
      xjp = configInfo.value.cloud_url + 'open_lottery/v_xjp.json';
      am = configInfo.value.cloud_url + 'open_lottery/v_am.json';
      klb = configInfo.value.cloud_url + 'open_lottery/v_fckl8.json';
      oldam = configInfo.value.cloud_url + 'open_lottery/v_oldam.json';
      document.addEventListener('visibilitychange', function () {
        clearInterval(cl);
        cl = setInterval(() => {
          openLotteryTiems();
        }, 1000);
        if (document.visibilityState == 'hidden') {
          //状态判断 离开浏览页面时
          if (propr.lottery == 1) {
            clearInterval(cler);
            cler = setInterval(() => {
              openLotteryTiem(xg);
            }, 2000);
          }
          if (propr.lottery == 2) {
            clearInterval(cler);
            cler = setInterval(() => {
              openLotteryTiem(old_am);
            }, 2000);
          }
          if (propr.lottery == 3) {
            clearInterval(cler);
            cler = setInterval(() => {
              openLotteryTiem(tw);
            }, 2000);
          }
          if (propr.lottery == 4) {
            clearInterval(cler);
            cler = setInterval(() => {
              openLotteryTiem(xjp);
            }, 2000);
          }
          if (propr.lottery == 5) {
            clearInterval(cler);
            cler = setInterval(() => {
              openLotteryTiem(am);
            }, 2000);
          }
          if (propr.lottery == 6) {
            clearInterval(cler);
            cler = setInterval(() => {
              openLotteryTiem(klb);
            }, 2000);
          }
          if (propr.lottery == 7) {
            clearInterval(cler);
            cler = setInterval(() => {
              openLotteryTiem(oldam);
            }, 2000);
          }
        }
      });
      let timestamp = new Date().valueOf();
      await nextTick();
      if (localStorage.getItem('number_attr') != null) {
        databs.shengxiaoList = JSON.parse(localStorage.getItem('number_attr')).shengxiaoList;
        databs.wuxingList = JSON.parse(localStorage.getItem('number_attr')).wuxingList;
      }
      if (localStorage.getItem('number_attr2') != null) {
        newdatas.shengxiaoList = JSON.parse(localStorage.getItem('number_attr2')).shengxiaoList;
        newdatas.wuxingList = JSON.parse(localStorage.getItem('number_attr2')).wuxingList;
      }
      //  numberss(propr.lottery,'2023')
      clearInterval(cl);
      cl = setInterval(() => {
        openLotteryTiems();
      }, 1000);
      if (propr.lottery == 1) {
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(xg);
        }, 2000);
      }
      if (propr.lottery == 2) {
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(old_am);
        }, 2000);
      }
      if (propr.lottery == 3) {
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(tw);
        }, 2000);
      }
      if (propr.lottery == 4) {
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(xjp);
        }, 2000);
      }
      if (propr.lottery == 5) {
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(am);
        }, 2000);
      }
      if (propr.lottery == 6) {
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(klb);
        }, 2000);
      }
      if (propr.lottery == 7) {
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(oldam);
        }, 2000);
      }
    });
    watch(() => propr.lottery, (val, oldVal) => {
      tw = configInfo.value.cloud_url + 'open_lottery/v_tw.json';
      xg = configInfo.value.cloud_url + 'open_lottery/v_xg.json';
      old_am = configInfo.value.cloud_url + 'open_lottery/v_am_plus.json';
      xjp = configInfo.value.cloud_url + 'open_lottery/v_xjp.json';
      am = configInfo.value.cloud_url + 'open_lottery/v_am.json';
      klb = configInfo.value.cloud_url + 'open_lottery/v_fckl8.json';
      oldam = configInfo.value.cloud_url + 'open_lottery/v_oldam.json';
      lotteryAaayVuex = $store.state.openLotteryList;
      numberList = ref({
        Day: 10,
        Moon: '02',
        Nq: '000',
        Time: "21点30分",
        Week: "周六",
        Year: "2024",
        Data: [{
          nim: "",
          number: "0",
          sx: ""
        }, {
          nim: "",
          number: "0",
          sx: ""
        }, {
          nim: "",
          number: "0",
          sx: ""
        }, {
          nim: "",
          number: "0",
          sx: ""
        }, {
          nim: "",
          number: "0",
          sx: ""
        }, {
          nim: "",
          number: "0",
          sx: ""
        }, {
          nim: "",
          number: "0",
          sx: ""
        }]
        // issue:'921',
        // number_attr:[
        //     {color:0,number:'0',shengXiao:'',wuXing:''},
        //     {color:0,number:'0',shengXiao:'',wuXing:''},
        //     {color:0,number:'0',shengXiao:'',wuXing:''},
        //     {color:0,number:'0',shengXiao:'',wuXing:''},
        //     {color:0,number:'0',shengXiao:'',wuXing:''},
        //     {color:0,number:'0',shengXiao:'',wuXing:''},
        //     {color:0,number:'0',shengXiao:'',wuXing:''},
        // ],
        // nextIssue:'972',
        // date:'',
      });

      clearInterval(cl);
      cl = setInterval(() => {
        openLotteryTiems();
      }, 1000);
      if (val == 1) {
        numberss(propr.lottery, '2023');
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(xg);
        }, 2000);
      }
      if (val == 2) {
        numberss(propr.lottery, '2023');
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(old_am);
        }, 2000);
      }
      if (val == 3) {
        numberss(propr.lottery, '2023');
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(tw);
        }, 2000);
      }
      if (val == 4) {
        numberss(propr.lottery, '2023');
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(xjp);
        }, 2000);
      }
      if (propr.lottery == 5) {
        numberss(propr.lottery, '2023');
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(am);
        }, 2000);
      }
      if (propr.lottery == 6) {
        numberss(propr.lottery, '2023');
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(klb);
        }, 2000);
      }
      if (propr.lottery == 7) {
        numberss(propr.lottery, '2023');
        clearInterval(cler);
        cler = setInterval(() => {
          openLotteryTiem(oldam);
        }, 2000);
      }
    }, {
      deep: true
    });
    const arrlottery = id => {
      let data = {
        5: '2024041',
        2: '041',
        1: '017',
        3: '015',
        4: '3947'
      };
      return data[id];
    };
    let numColor = computed(() => item => {
      //号码的颜色
      if (parseInt(item) < 50 && parseInt(item) > 0 && item != '4' && item != '9') {
        return colorarray[cons(item, numarrays)];
      } else {
        return 0;
      }
    });
    let numSx = computed(() => item => {
      //号码的属性
      if (parseInt(item) < 50 && parseInt(item) > 0 && item != '4' && item != '9') {
        let sx = '';
        let wx = '';
        if (arrlottery(propr.lottery) == nextissue.value) {
          newdatas.shengxiaoList.forEach((element, index) => {
            element.list.forEach(items => {
              if (items.number == item) {
                sx = element.name;
              }
            });
          });
          newdatas.wuxingList.forEach((element, index) => {
            element.list.forEach(items => {
              if (items.number == item) {
                wx = element.name;
              }
            });
          });
        } else {
          databs.shengxiaoList.forEach((element, index) => {
            element.list.forEach(items => {
              if (items.number == item) {
                sx = element.name;
              }
            });
          });
          databs.wuxingList.forEach((element, index) => {
            element.list.forEach(items => {
              if (items.number == item) {
                wx = element.name;
              }
            });
          });
        }
        return `${sx}/${wx}`;
      } else {
        return '-/-';
      }
    });
    let numarrays = [[1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46], [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48], [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49]];
    let cons = (d, array) => {
      let b = 0;
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array[i].length; j++) {
          if (array[i][j] == d) {
            b = i;
          }
        }
      }
      return b;
    };
    let colorarray = ['1', '2', '3'];
    const yearLottery = lottery => {
      if (sessionStorage.getItem("nextLottery") != null) {
        let nextLottery = JSON.parse(sessionStorage.getItem("nextLottery")).datas;
        let year = '';
        nextLottery.forEach(item => {
          if (item.lotteryType == lottery) {
            year = item.lotteryTime.split("-")[0];
            return;
          }
        });
        return year;
      } else {
        var datesc = new Date();
        return datesc.getFullYear();
      }
    };
    let nextissue = ref(null);
    let lotteryGet = (url, lottery) => {
      localStorage.setItem('yearone', yearLottery(lottery));
      axios.get(url).then(res => {
        let body = res.data.data;
        // console.log(body);
        numberList.value = body;
        //     let bodyArr = body.split(',');
        //     numberList.value.issue = bodyArr[0];
        //     numberList.value.number_attr[0].number = bodyArr[1];
        //     numberList.value.number_attr[1].number = bodyArr[2];
        //     numberList.value.number_attr[2].number = bodyArr[3];
        //     numberList.value.number_attr[3].number = bodyArr[4];
        //     numberList.value.number_attr[4].number = bodyArr[5];
        //     numberList.value.number_attr[5].number = bodyArr[6];
        //     numberList.value.number_attr[6].number = bodyArr[7];
        //     numberList.value.nextIssue = bodyArr[8];
        //     numberList.value.date = `${yearLottery(lottery)}/${bodyArr[9]}/${bodyArr[10]} 星期${bodyArr[11]}${bodyArr[12]}`;
        localStorage.setItem('qi', `${body.Year}年${body.Qi}期`);
        // $store.state.lotteryType
        // let arr = localStorage.getItem('lotteryNumber')==null?[]:JSON.parse(localStorage.getItem('lotteryNumber'))
        // arr.push({lottery:lottery,numberList:numberList.value})
        // localStorage.setItem('lotteryNumber', JSON.stringify(arr))
        // lotteryAaayVuex.push({lottery:lottery,numberList:numberList.value})

        lotteryAaayVuex.push({
          lottery: lottery,
          numberList: numberList.value
        });
        let dd = arrayUnique(lotteryAaayVuex, 'lottery');
        $store.commit('handleLottery', dd);
      });
    };
    let arrayUnique = (arr, name) => {
      let hash = {};
      return arr.reduce((acc, cru, index) => {
        if (!hash[cru[name]]) {
          hash[cru[name]] = {
            index: acc.length
          };
          acc.push(cru);
        } else {
          acc.splice(hash[cru[name]]['index'], 1, cru);
        }
        return acc;
      }, []);
    };
    let openLotteryTiem = url => {
      let timestamp = new Date().valueOf();
      let next = JSON.parse(localStorage.getItem('nextLottery'));
      var tiem = '2100-10-10 9点24分';
      if (next != null) {
        next.forEach(item => {
          if (item.lotteryType == propr.lottery) {
            tiem = item.lotteryTime + ' ' + item.time;
          }
        });
      }
      let dates = tiem.split(' ');
      let Year = dates[0].replace(/-/g, '/');
      let s = dates[1].split('点').join(':');
      let days = s.substring(0, s.length - 1);
      var oDate = new Date(); //获取日期对象
      var oldTime = oDate.getTime(); //现在距离1970年的毫秒数
      var newDate = new Date(`${Year} ${days}:00 GMT+08:00`);
      var newTime = newDate.getTime() + 60000 * 10; //2019年距离1970年的毫秒数  加五分钟 五分钟后停止访问
      var second = Math.floor((newTime - oldTime) / 1000); //未来时间距离现在的秒数
      var day = Math.floor(second / (3600 * 24)); //整数部分代表小时；
      var hour = Math.floor(second / 3600) % 24; //整数部分代表小时；
      second %= 3600; //余数代表 剩下的秒数；
      var minute = Math.floor(second / 60);
      second %= 60;
      let dgs = djs();
      let DateTime = timestampToDateTime(dgs);
      if (propr.lottery == 2) {
        if (DateTime[0] == 21 && DateTime[1] >= 26 && DateTime[1] <= 36) {
          lotteryGet(`${url}?${timestamp}`, propr.lottery);
        }
      } else if (propr.lottery == 5) {
        if (DateTime[0] == 22 && DateTime[1] >= 26 && DateTime[1] <= 36) {
          lotteryGet(`${url}?${timestamp}`, propr.lottery);
        }
      } else {
        if (minute <= 18 && minute >= 0 && day == 0 && hour == 0) {
          // 提前18分钟开始请求 相当于 21:22 开始请求
          lotteryGet(`${url}?${timestamp}`, propr.lottery);
        }
      }
    };
    let openLotteryTiems = () => {
      let timestamp = new Date().valueOf();
      let next = JSON.parse(sessionStorage.getItem('nextLottery')).datas;
      var tiem = '2100-10-10 9点24分';
      if (next != null) {
        next.forEach(item => {
          if (item.lotteryType == propr.lottery) {
            tiem = item.lotteryTime + ' ' + item.time;
          }
        });
      }
      let dates = tiem.split(' ');
      let Year = dates[0].replace(/-/g, '/');
      let s = dates[1].split('点').join(':');
      let days = s.substring(0, s.length - 1);
      var oDate = new Date(); //获取日期对象
      var oldTime = oDate.getTime(); //现在距离1970年的毫秒数
      var newDate = new Date(`${Year} ${days}:00 GMT+08:00`);
      var newTime = newDate.getTime(); //2019年距离1970年的毫秒数  加五分钟 五分钟后停止访问
      var second = Math.floor((newTime - oldTime) / 1000); //未来时间距离现在的秒数
      var day = Math.floor(second / (3600 * 24)); //整数部分代表小时；
      var hour = Math.floor(second / 3600) % 24; //整数部分代表小时；
      second %= 3600; //余数代表 剩下的秒数；
      var minute = Math.floor(second / 60);
      second %= 60;
      let dgs = djs();
      let DateTime = timestampToDateTime(dgs);
      if (propr.lottery == 2) {
        if (DateTime[0] == 21 && DateTime[1] >= 29 && DateTime[1] <= 33) {
          databs.djs = `00:00:00`;
        } else {
          djsTow(propr.lottery);
        }
      } else if (propr.lottery == 5) {
        if (DateTime[0] == 22 && DateTime[1] >= 29 && DateTime[1] <= 33) {
          databs.djs = `00:00:00`;
        } else {
          djsTow(propr.lottery);
        }
      } else {
        if (day < 0 && hour < 0) {
          // 提前18分钟开始请求 相当于 21:22 开始请求
          databs.djs = `00:00:00`;
        } else {
          databs.djs = `${hour + day * 60 >= 10 ? hour + day * 24 : '0' + (hour + day * 60)}:${minute >= 10 ? minute : '0' + minute}:${second >= 10 ? second : '0' + second}`;
        }
      }
    };
    let djsTow = lootery => {
      var now = new Date();
      // 获取本地时区
      var localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // 设置中国时区
      var chinaTimeZone = 'Asia/Shanghai';
      // 使用本地时区创建日期对象
      var localTime = new Date(now);
      // 将本地时间转换为中国时区
      var chinaTime = new Date(localTime.toLocaleString('en-US', {
        timeZone: chinaTimeZone
      }));
      var targetTime = new Date(chinaTime);
      // 设置目标时间为今天的21:30
      if (lootery == 5) {
        targetTime.setHours(22, 33, 0, 0);
      } else {
        targetTime.setHours(21, 30, 0, 0);
      }
      // 如果当前时间已经过了21:30，则目标时间改为明天的21:30
      if (chinaTime >= targetTime) {
        targetTime.setDate(chinaTime.getDate() + 1);
      }
      // 计算时间戳（单位：毫秒）
      var nowTimestamp = chinaTime.getTime();
      var seconds = Math.floor(nowTimestamp % (1000 * 60) / 1000);
      var targetTimestamp = targetTime.getTime();
      // 计算时间间隔（毫秒）
      var timeDiff = targetTimestamp - nowTimestamp;
      //计算剩余的小时、分钟、秒
      var hours = Math.floor(timeDiff / (1000 * 60 * 60));
      var minutes = Math.floor(timeDiff % (1000 * 60 * 60) / (1000 * 60));
      var seconds = Math.floor(timeDiff % (1000 * 60) / 1000);
      databs.djs = `${hours >= 10 ? hours : '0' + hours}:${minutes >= 10 ? minutes : '0' + minutes}:${seconds >= 10 ? seconds : '0' + seconds}`;
    };
    let djs = () => {
      var now = new Date();
      // 获取本地时区
      var localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // 设置中国时区
      var chinaTimeZone = 'Asia/Shanghai';
      // 使用本地时区创建日期对象
      var localTime = new Date(now);
      // 将本地时间转换为中国时区
      var chinaTime = new Date(localTime.toLocaleString('en-US', {
        timeZone: chinaTimeZone
      }));
      var targetTime = new Date(chinaTime);
      // 设置目标时间为今天的21:30
      targetTime.setHours(21, 30, 0, 0);
      // 如果当前时间已经过了21:30，则目标时间改为明天的21:30
      if (chinaTime >= targetTime) {
        targetTime.setDate(chinaTime.getDate() + 1);
      }
      // 计算时间戳（单位：毫秒）
      var nowTimestamp = chinaTime.getTime();
      return nowTimestamp;
    };
    function timestampToDateTime(timestamp) {
      // 创建一个 Date 对象
      var date = new Date(timestamp);

      // 获取年、月、日、时、分、秒
      var year = date.getFullYear();
      var month = date.getMonth() + 1; // 月份从0开始，需要加1
      var day = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();

      // 格式化输出
      return [(hours < 10 ? "0" : "") + hours, (minutes < 10 ? "0" : "") + minutes, (seconds < 10 ? "0" : "") + seconds];
    }
    onDeactivated(() => {
      clearInterval(cler);
    });
    __expose({
      databs
    });
    return (_ctx, _cache) => {
      return !__props.types ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("p", null, [_createTextVNode("第 "), _createElementVNode("span", null, _toDisplayString(__props.data.year + __props.data.issue), 1), _createTextVNode(" 期开奖结果 ")])])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.data.number_attr, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("div", {
          class: _normalizeClass('num class' + item.color)
        }, [_createElementVNode("span", null, _toDisplayString(item.number), 1)], 2), _createElementVNode("div", _hoisted_5, _toDisplayString(item.shengXiao) + "/" + _toDisplayString(item.wuXing), 1)]);
      }), 128))])])])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("p", null, [_createTextVNode("第 "), _createElementVNode("span", null, _toDisplayString(_unref(numberList).Qi), 1), _createTextVNode(" 期 ")])]), _createElementVNode("div", _hoisted_9, "直播倒计时:" + _toDisplayString(_unref(databs).djs), 1), _createElementVNode("div", {
        class: "tk-lottery-history",
        onClick: _cache[0] || (_cache[0] = $event => _unref(handleNext)())
      }, "查看历史记录")]), _createElementVNode("div", _hoisted_10, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(numberList).Data, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("div", {
          class: _normalizeClass('num class' + _unref(numColor)(item.number))
        }, [_unref(numColor)(item.number) == 0 ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(item.number), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(item.number), 1))], 2), _createElementVNode("div", _hoisted_13, _toDisplayString(item.sx + '/' + item.nim), 1)]);
      }), 128))])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("p", null, "第" + _toDisplayString(_unref(numberList).Nq) + "期 " + _toDisplayString(_unref(numberList).Moon + '月' + _unref(numberList).Day + '日' + _unref(numberList).Time), 1)])]));
    };
  }
};