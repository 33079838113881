import serve from './serve'

export function discussList(params){//高手论坛列表
    return serve({
        url:'v1/discuss/list',
        method :'get',
        params:params,
    })
}
export function discussCreate(params){//高手论坛发布
    return serve({
        url:'v1/discuss/create',
        method :'post',
        data:params,
    })
}

export function discussDetail(params){//高手论坛详情
    return serve({
        url:'v1/discuss/detail',
        method :'get',
        params:params,
    })
}

export function discussFollow(params){//高手论坛详情
    return serve({
        url:'v1/discuss/follow',
        method :'post',
        data:params,
    })
}
export function discussPrevious(params){//加载上期内容
    return serve({
        url:'v1/discuss/previous',
        method :'post',
        data:params,
    })
}
