import serve from './serve'

export function login(params){//登陆
    return serve({
        url:'v1/login/index',
        method :'post',
        data:params,
    })
}

export function register(params){//注册
    return serve({
        url:'v1/login/register',
        method :'post',
        data:params,
    })
}

export function captcha(params){//验证码
    return serve({
        url:'v1/login/captcha',
        method :'get',
        params:params,
    })
}
export function loginChat(params){//登陆聊天室
    return serve({
        url:'v1/login/loginChat',
        method :'post',
        data:params,
    })
}
export function captchaTow(params){//验证码检验
    return serve({
        url:'v1/graph/verify',
        method :'get',
        params:params,
    })
}

export function mobileSend(params){//发送短信
    return serve({
        url:'v1/mobile/send',
        method :'get',
        params:params,
    })
}
export function mobileLogin(params){//手机登录
    return serve({
        url:'v1/login/mobileLogin',
        method :'post',
        data:params,
    })
}
export function mobileVerify(params){//绑定手机号
    return serve({
        url:'v1/mobile/verify',
        method :'get',
        params:params,
    })
}


