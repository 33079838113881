import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-43e78f1c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-nav-tabs"
};
const _hoisted_2 = {
  class: "tk-tabs"
};
const _hoisted_3 = {
  key: 0,
  class: "tips"
};
import { useStore } from 'vuex';

import { reactive, toRefs } from 'vue';
export default {
  __name: 'navTabs',
  props: {
    nameLeft: {
      type: String,
      default: '系统公告'
    },
    nameRight: {
      type: String,
      default: '站内消息'
    }
  },
  emits: ['principalTans'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const store = useStore(); //获取vuex

    let data = reactive({
      activeIndex: 0,
      msg: store.state.msg
    });
    let {
      activeIndex,
      msg
    } = toRefs(data);
    let principalTans = index => {
      data.activeIndex = index;
      data.msg = 0;
      emits('principalTans', index);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("ul", null, [_createElementVNode("li", {
        class: _normalizeClass(_unref(activeIndex) == 0 ? 'active' : ''),
        onClick: _cache[0] || (_cache[0] = $event => _unref(principalTans)(0))
      }, _toDisplayString(__props.nameLeft), 3), _createElementVNode("li", {
        class: _normalizeClass(_unref(activeIndex) == 1 ? 'active' : ''),
        onClick: _cache[1] || (_cache[1] = $event => _unref(principalTans)(1))
      }, [_createTextVNode(_toDisplayString(__props.nameRight) + " ", 1), __props.nameRight == '站内消息' && _unref(msg) > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(msg)), 1)) : _createCommentVNode("", true)], 2)])])]);
    };
  }
};